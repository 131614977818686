import { useMemo } from "react";
import { DOTS } from "./constants";
import { IUsePagination } from "./interfaces";
import jwtDecode from "jwt-decode";
import CryptoJS from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import { GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID } from "../queries/vip-workflow";
import { GET_BROKER_PROFILE, GET_PROFILE } from "../queries/auth";
import { CRYPTO_JS_SECRET } from "./constants";

export const checkToken = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  if (jwtValue && (parsedToken.role === "RECIPIENT" || parsedToken.role === "BROKER")) {
    return true;
  }

  return false;
};

export const getRole = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  return parsedToken.role;
};

export const getUserRole = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  return parsedToken?.role || null;
};

export const isEmailConfirmed = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  return parsedToken?.isEmailConfirmed;
};

export const getUserEmail = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  return parsedToken?.email;
};

export const getUserId = () => {
  const jwt = document.cookie.match(/jwt=[^\s]+/);
  const jwtValue = jwt && jwt[0];
  const parsedToken: any = jwtValue && jwtDecode(jwtValue.replace("jwt=", ""));

  return parsedToken?.userId;
};

const range = (start: number, end: number): number[] => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount,
  currentPage,
  totalPageCount,
}: IUsePagination) => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage, totalPageCount]);

  return paginationRange;
};

export function formatPrice(value: any) {
  if (value == null || value === undefined) return null;

  return Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value);
}

export const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

export const transformPhoneNumber = (recipientPhone: string): string => {
  if (recipientPhone) {
    const LOCAL_PHONE_NUMBER_LENGTH = 10;
    const MAX_PHONE_NUMBER_LENGTH = 11;
    let globalPhone = recipientPhone;
    let phoneLength = globalPhone.length;
    let localPhone = globalPhone.substring(phoneLength - LOCAL_PHONE_NUMBER_LENGTH, phoneLength);
    let countryCode = "1";

    if (phoneLength > MAX_PHONE_NUMBER_LENGTH || phoneLength < LOCAL_PHONE_NUMBER_LENGTH) {
      return recipientPhone;
    }

    if (phoneLength > LOCAL_PHONE_NUMBER_LENGTH) {
      countryCode = globalPhone.substring(0, phoneLength - LOCAL_PHONE_NUMBER_LENGTH);
    }

    return `+${countryCode}(${localPhone.substring(0, 3)})-${localPhone.substring(
      3,
      6,
    )}-${localPhone.substring(6, LOCAL_PHONE_NUMBER_LENGTH)}`;
  } else {
    return "";
  }
};

export const generateMailtoLink = async (
  client: any,
  pathname: string,
  setEmailLink: (arg0: string) => void
) => {
  const SUPPORT_MAIL = "support@elevatevip.zendesk.com ";
  
  const getUserData = async () => {
    if (checkToken()) {
      if (getRole() === "RECIPIENT") {
        const { data } = await client.query({
          query: GET_PROFILE,
        });

        return data?.getProfile.email;
      }

      if (getRole() === "BROKER") {
        const { data } = await client.query({
          query: GET_BROKER_PROFILE,
        });

        return data?.getProfile.email;
      }
    }
    return "";
  };

  const getPackageInfoForSupportEmail = async (pathname: string) => {
    if (pathname.match("/package-info")) {
      const slashesReturnedToEncryptedInvoiceId = pathname
        .replace(/HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61/g, "/")
        .replace("/package-info/", "");

      const decryptedInvoiceId = Number.parseFloat(
        CryptoJS.decrypt(slashesReturnedToEncryptedInvoiceId.toString(), CRYPTO_JS_SECRET).toString(
          enc,
        ),
      );

      if (Number.isNaN(decryptedInvoiceId)) return;

      const { data } = await client.query({
        query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
        variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: "" },
        fetchPolicy: "cache-first",
      });

      return `%0D%0A Event Name: ${data?.getOnePackageWithDetailsForClient.eventName} %0D%0A Event Date: ${data?.getOnePackageWithDetailsForClient.eventDate} %0D%0A Invoice ID: ${data?.getOnePackageWithDetailsForClient.invoiceId} %0D%0A Invoice link: https://app.dev.elevatevip.live${pathname}`;
    }
    return "";
  };

  const userEmail = await getUserData();
  const invoiceInfo = await getPackageInfoForSupportEmail(pathname);

  const FEEDBACK_MAIL_BODY = `%0D%0A
    %0D%0A
    %0D%0A ---------------
    %0D%0A
    %0D%0A PLEASE WRITE YOUR MESSAGE ABOVE THIS LINE AND LEAVE EVERYTHING BELOW
    %0D%0A
    %0D%0A Elevate VIP Feedback
    ${userEmail ? `%0D%0A %0D%0A User: ${userEmail}` : ""}
    ${invoiceInfo}
  `;

  const FEEDBACK_MAIL_SUBJECT = invoiceInfo
    ? `Elevate VIP Feedback: Invoice ${invoiceInfo}`
    : "Elevate VIP Feedback";

  setEmailLink(`mailto:${SUPPORT_MAIL}?subject=${FEEDBACK_MAIL_SUBJECT}&body=${FEEDBACK_MAIL_BODY}`);
};

export const truncateString = (str: string, length = 40) => {
  if (!str) return '';

  const words = str.split('');

  if (words.length > length) {
    return words.slice(0, length).join('') + '...';
  }

  return str;
};

export const getSplittedName = (name: string) => {
  const { firstName, lastName } = name?.trim().split(' ').reduce(
		(acc, namePart, idx, arr) => {
			if (arr.length === 1) {
				acc.firstName = namePart;
				acc.lastName = namePart;
			} else if (idx === arr.length - 1) {
				acc.lastName = namePart;
			} else if (idx === 0) {
				acc.firstName = namePart;
			} else {
				acc.firstName += ` ${namePart}`;
			}

			return acc;
		}, {
			firstName: '',
			lastName: '',
		}
	);
  
  return {
    firstName,
    lastName,
  };
};

export const lowercaseFirstLetter = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toLowerCase() + str.slice(1);
};



