import { Row, Col, CardBody } from "reactstrap";
import { successMsg } from "../../cache/vars";
import PendingLabel from "../labels/Pending";
import "./card.scss";
import FlightInfoPopover from "../Popovers/FlightInfoPopover";
import CardDetails from "../StagedCard/CardDetails/CardDetails";

import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';

const CartLayout = ({
  schemaConfig,
  cartData,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isLearnMoreOpened,
  dataKey,
  isPublished,
}: any) => {
  const renderFieldValue = (cartDetails: any, config: any) => {
    if (config.alignToLeft) return;

    if (config.name === "flightNumber" || config.name === "departureFlightNumber") {
      return (
        <>
          <span>
            {config.render(
              cartDetails?.[config.name as keyof typeof cartDetails]
                ? cartDetails[config.name as keyof typeof cartDetails]
                : config.defaultValue,
              cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
                ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
                : config.defaultValue,
            )}
          </span>
          {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
          {cartDetails?.[config.name as keyof typeof cartDetails] ? (
            <FlightInfoPopover parsedCardDetails={parsedCardDetails} config={config} cartInfoId={cartInfoId} cardGroup={cartData?.group} />
          ) : null}
        </>
      );
    }

    if (config.render) {
      return (
        <span>
          {config.render(
            cartDetails?.[config.name as keyof typeof cartDetails]
              ? cartDetails[config.name as keyof typeof cartDetails]
              : config.defaultValue,
            cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
              ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
              : config.defaultValue,
            cartDetails?.[config.alternativeDataName as keyof typeof cartDetails]
              ? cartDetails[config.alternativeDataName as keyof typeof cartDetails]
              : null,
            cartDetails?.alternativeFirstName || '',
            cartDetails?.alternativeLastName || ''  
          )}
        </span>
      );
    }

    if (config.inputType === "checkbox") {
      return (
        <input type="checkbox" checked={cartDetails?.[config.name as keyof typeof cartDetails]} />
      );
    }

    if (config.showDataFrom) {
      return (
        <span>
          {cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
            ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
            : config.defaultValue}
        </span>
      );
    }

    if (config.isAddress) {
      return cartDetails?.[config.linkValue as keyof typeof cartDetails] ? (
        <a href={cartDetails[config.linkValue as keyof typeof cartDetails]} target="_blank">
          {config.linkLabel
            ? cartDetails[config.linkLabel as keyof typeof cartDetails]
            : cartDetails[config.name as keyof typeof cartDetails]}
        </a>
      ) : (
        cartDetails[config.name as keyof typeof cartDetails]
      );
    }

    if (config.isLink) {
      return cartDetails?.[config.name as keyof typeof cartDetails] ? (
        <a href={cartDetails[config.name as keyof typeof cartDetails]} target="_blank">
          {config.linkLabel
            ? cartDetails[config.linkLabel as keyof typeof cartDetails]
            : cartDetails[config.name as keyof typeof cartDetails]}
        </a>
      ) : (
        config.defaultValue
      );
    }

    if (config.name === "trackingNumber") {
      if (
        cartDetails?.[config.name as keyof typeof cartDetails]?.toUpperCase() ===
        config.hideFieldIfValueEqualsTo
      ) {
        return;
      }

      return cartDetails?.[config.name as keyof typeof cartDetails] ? (
        <>
          <a
            href={`https://www.google.com/search?q=${
              cartDetails?.[config.name as keyof typeof cartDetails]
            }`}
            target="_blank"
          >
            {cartDetails?.[config.name as keyof typeof cartDetails]}
          </a>
          &nbsp;&nbsp;
          {cartDetails?.[config.name as keyof typeof cartDetails] ? (
            <button
              style={{
                border: "none",
                background: "none",
                color: "#525f7f",
                padding: "0",
                textDecoration: "underline",
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  cartDetails?.[config.name as keyof typeof cartDetails],
                );
                successMsg("Copied");
              }}
            >
              <i className="fa fa-copy"></i>
            </button>
          ) : null}
        </>
      ) : (
        <PendingLabel />
      );
    }

    return (
      <span>
        {cartDetails?.[config.name as keyof typeof cartDetails]
          ? cartDetails[config.name as keyof typeof cartDetails]
          : config.defaultValue}
      </span>
    );
  };

  const renderFieldLabel = (cartDetails: any, config: any) => {
    if (config.alignToLeft) {
      if (config.renderDescription) {
        return config.renderDescription(
          cartDetails?.[config.name as keyof typeof cartDetails]
            ? cartDetails[config.name as keyof typeof cartDetails]
            : config.defaultValue,
          cartDetails.group,
        );
      }

      if (config.render) {
        return config.render(
          cartDetails?.[config.name as keyof typeof cartDetails]
            ? cartDetails[config.name as keyof typeof cartDetails]
            : config.defaultValue,
          cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
            ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
            : config.defaultValue,
        );
      }

      return cartDetails?.[schemaConfig.name as any];
    } else {
      if (
        config.name === "trackingNumber" &&
        cartDetails?.[config.name as keyof typeof cartDetails]?.toUpperCase() ===
          config.hideFieldIfValueEqualsTo
      ) {
        return;
      }

      if (typeof config.label === "function") {
        return schemaConfig.label(
          parsedCardDetails?.[schemaConfig.name as any]
            ? parsedCardDetails[schemaConfig.name as any]
            : "-",
        );
      }

      if (config.labelInCart) {
        return config.labelInCart;
      }

      return schemaConfig.label;
    }
  };
  
  if (schemaConfig?.name === 'notes') {
    return (
      <Row style={{ ...schemaConfig.style }} key={cartInfoId}>
        <Col xs="12">
          <div className='divider'></div>
          
          <CardDetails
            cardIcon={flagIcon}
            cardTitle={schemaConfig?.label || 'Additional Notes'}
            cardDescription={parsedCardDetails.notes}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row style={{ ...schemaConfig.style }} key={cartInfoId}>
        <Col
          xs={schemaConfig.alignToLeft ? "11" : "6"}
          className={schemaConfig.bold ? "d-flex font-weight-bold" : "d-flex"}
          style={{ paddingLeft: schemaConfig.icon ? "" : "3.25rem" }}
        >
          {schemaConfig.icon ? (
            <div>
              <img src={schemaConfig.icon} alt="config-icon" style={{ width: "24px", textAlign: "center", marginRight: "0.5rem" }}/>
            </div>
          ) : null}
          {schemaConfig.isTextContainsLink ? (
            <span
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{
                __html: parsedCardDetails?.[schemaConfig.name as any],
              }}
            ></span>
          ) : (
            <span style={{ whiteSpace: "pre-line" }}>
              {renderFieldLabel(parsedCardDetails, schemaConfig)}
            </span>
          )}
          {schemaConfig.learnMoreButton ? (
            <button
              style={{ border: "none", background: "none", marginLeft: "15px" }}
              onClick={(e) => handleOpenLearnMore(e, cartData.cartDetails.packageDetailsId)}
            >
              {isLearnMoreOpenedId.includes(cartData.cartDetails?.packageDetailsId) ? (
                <i className="fa fa-caret-up text-body fa-lg" />
              ) : (
                <i className="fa fa-caret-down text-body fa-lg" />
              )}
            </button>
          ) : null}
        </Col>

        {!schemaConfig.learnMore ? (
          <Col xs={schemaConfig.showMoreButton ? "4" : "6"}>
            {renderFieldValue(parsedCardDetails, schemaConfig)}
          </Col>
        ) : null}

        {schemaConfig.learnMore && isLearnMoreOpened ? (
          <Col xs="12">{renderFieldValue(parsedCardDetails, schemaConfig)}</Col>
        ) : null}

        {schemaConfig.showMoreButton ? (
          <Col>
            <button
              style={{ border: "none", background: "none", marginLeft: "15px" }}
              onClick={(e) =>
                handleOpenDetails(
                  e,
                (dataKey === "Airport Transfer" || dataKey === "Vegas Limo Transfer") &&
                  !parsedCardDetails.transfer1 &&
                  !parsedCardDetails.transfer2
                  ? parsedCardDetails.transferId
                  : cartData.cartDetails.packageDetailsId,
                )
              }
            >
              {isCartInfoOpenedId.includes(
                (cartData.cartDetails?.packageDetailsId && parsedCardDetails?.transferId) ||
                  cartData.cartDetails?.packageDetailsId ||
                  parsedCardDetails?.transferId,
              ) ? (
                <span style={{ color: "#337ab7", textDecoration: "underline" }}>Hide</span>
              ) : (
                <span style={{ color: "#337ab7", textDecoration: "underline" }}>Show More</span>
              )}
            </button>
          </Col>
        ) : null}
      </Row>
      
      {isPublished ? <div className='divider'></div> : null}
    </>
  );
};

export default CartLayout;
