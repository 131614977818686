import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery } from "@apollo/client";
import { Modal, Spinner } from "reactstrap";
import { GET_TICKETS_LIST_FOR_TICKETS_MODAL } from "../../queries/vip-workflow";
import { Document, Page, pdfjs } from "react-pdf";
import UploadedTickets from "./UploadedTickets";
import UploadedUrls from "./UploadedUrls";
import CardGuaranteed from "../StagedCard/CardGuaranteed/CardGuaranteed";
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import { lowercaseFirstLetter } from '../../utils/services';
import ticketsIcon from "../../assets/img/icons/cards/tickets-icon.svg";
import downloadBlackIcon from "../../assets/img/icons/cards/download-black-icon.svg";
import calendarClockIcon from "../../assets/img/icons/cards/calendar-clock-icon.svg";
import flagIcon from "../../assets/img/icons/cards/flag-icon.svg";
import ticketConfigIcon from "../../assets/img/icons/cards/ticket-config-icon.svg";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./editPackageDetailsModal.scss";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TicketsModal = ({
  isModalOpened,
  setIsModalOpened,
  isShowEventTicketsAdditionalMessage,
  invoiceId,
  ticketDescription,
  packageOrderWithDetails,
}: any) => {
  const [file, setFile] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string>("pdf");
  const [numPages, setNumPages] = useState<null | number>(null);
  const [fileScale, setFileScale] = useState(1.25);
  
  const formatEventDate = (dateStr: any) => {
    if (!dateStr) return '';

    const [date, time] = dateStr.split(', ');
    const formattedTime = time.toUpperCase().replace(' PM', ' PM').replace(' AM', ' AM');
    return `${date} / ${formattedTime}`;
  };
  
  const ticketJsonData = packageOrderWithDetails?.orderDetails.find(
    (cart: Record<string, any>) => cart.group === 'Ticket',
  )?.cartDetails?.cartJsonData;
  
  const ticketJsonDataDate = packageOrderWithDetails?.orderDetails.find(
    (cart: Record<string, any>) => cart.group === 'Ticket',
  )?.cartDetails?.ticketsSentDate;
  
  const parsedTicketJsonData = ticketJsonData ? JSON.parse(ticketJsonData) : null;
  const ticketJsonNotes = parsedTicketJsonData?.notes;
  
  const defaultAddressParts = [
    packageOrderWithDetails?.venue || '',
    packageOrderWithDetails?.venueAddress || '',
    packageOrderWithDetails?.venueCity || '',
    packageOrderWithDetails?.venuePostalCode || '',
    packageOrderWithDetails?.stateName || '',
  ];
  
  const addressParts = ticketJsonData?.includes('placeValue')
    ? defaultAddressParts.slice(0, 2)
    : defaultAddressParts;
  
  const address = addressParts
    .filter((part) => part?.trim())
    .join(', ');

  const [getTickets, { data: ticketsData, loading: ticketsLoading }] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: "network-only",
  });

  const modalWidth = document?.getElementById("ticketsModal")?.offsetWidth;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (modalWidth && modalWidth < 800 && fileScale !== 0.75) {
      setFileScale(0.75);
    }

    if (modalWidth && modalWidth >= 800 && fileScale !== 1.25) {
      setFileScale(1.25);
    }

    if (modalWidth && modalWidth < 400 && fileScale !== 0.59) {
      setFileScale(0.59);
    }
  }, [modalWidth]);
  
  useEffect(() => {
    if (isModalOpened) {
      getTickets({ variables: { invoiceId } });
    }
  }, [isModalOpened]);
  
  const handleDownload = async () => {
    if (file) {
      try {
        const link = document.createElement("a");
        link.href = file;
        link.download = `ticket_${fileType}.${fileType === 'pdf' ? 'pdf' : 'jpg'}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    } else {
      console.warn("No file available to download.");
    }
  };
  

  const determineFileType = (fileContent: string) => {
    if (fileContent.startsWith("data:application/pdf")) {
      setFileType("pdf");
    } else if (fileContent.startsWith("data:image/")) {
      setFileType("image");
    }
  };

  const onFileClick = (file: string | undefined) => {
    if (file) {
      determineFileType(file);
      setFile(file);
    }
  };

  const renderFile = () => {
    if (fileType === 'pdf') {
      return file ? (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={fileScale} />
          ))}
        </Document>
      ) : null;
    } else if (fileType === 'image') {
        return file ? (
          <img
            src={file}
            style={{ 
              maxWidth: modalWidth && modalWidth < 800 && fileScale !== 0.75 ? '75%' : '100%',
              maxHeight: modalWidth && modalWidth < 800 && fileScale !== 0.75 ? '75%' : '100%',
              display: 'block', 
              margin: '0 auto'
            }}
            alt=""
          />
        ) : null;
    }
  };

  const renderTicketsForCard = (orderDetail: any) => {
    if (!orderDetail.cartDetails.isPublished) {
      return null;
    }

    const ticketsList = ticketsData?.getTicketsListForTicketsModalForClient;
    if (!ticketsList) return null;

    const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));
    const ticketsForCard = ticketEntries.filter(([key, value]: any) =>
      value.some((ticket: any) => ticket.packageDetailsId === orderDetail.cartDetails.packageDetailsId)
    );

    if (ticketsForCard.length === 0) {
      return null;
    }

    return ticketsForCard.map(([key, value]: any) => {
      const ticketsForUploadedTickets = value.filter((ticket: any) => !ticket?.eTicketLink?.length);
      const ticketsForUploadedUrls = value.filter((ticket: any) => ticket?.eTicketLink?.length);

      if (ticketsForUploadedTickets.length === 0 && ticketsForUploadedUrls.length === 0) {
        return null;
      }
      
      if (key === 'Event Details' && ((!ticketsForUploadedTickets.filter((ticket: any) => ticket.key).length && !ticketsForUploadedUrls.length))) {
        return null;
      }

      return (
        <React.Fragment key={key}>
          <div>
          <h2 className="text-capitalize">
            {key.includes("Details")
              ? key.replace("Details", "Tickets")
              : key.includes("Tickets")
              ? key
              : `${key} Tickets`}
          </h2>

          </div>
          <div>
            {ticketsForUploadedTickets.filter((ticket: any) => ticket.key).map((ticket: any, index: number) => (
              <UploadedTickets key={index} ticket={ticket} index={index} setFile={onFileClick} />
            ))}
            {ticketsForUploadedUrls.map((ticket: any, index: number) => (
              <UploadedUrls key={index} ticket={ticket} index={index} />
            ))}
          </div>
          <div className="divider"></div>
        </React.Fragment>
      );
    });
  };

  const renderCards = () => {
    if (!packageOrderWithDetails) return null;

    const publishedCards = packageOrderWithDetails.orderDetails.filter((orderDetail: any) => orderDetail.cartDetails.isPublished);
    const cardsWithTickets = publishedCards.filter((orderDetail: any) => {
      const ticketsList = ticketsData?.getTicketsListForTicketsModalForClient;
      if (!ticketsList) return false;

      const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));

      const ticketsForCard = ticketEntries.find(([key, value]: any) =>
        value.some((ticket: any) => ticket.packageDetailsId === orderDetail.cartDetails.packageDetailsId &&
        (ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink))
      );

      return ticketsForCard ? true : false;
    });
    
    if (!cardsWithTickets?.length && !isShowEventTicketsAdditionalMessage) {
      return (
        <div>
          <div>
            <h2 className="text-capitalize">Event Tickets</h2>
          </div>
          <div className="d-flex align-items-center mt-2 mb-4 justify-content-between">
            <div style={{ width: "100%" }}>
              <img src={calendarClockIcon} alt="clock-icon" className="mr-2"/>
              <span className="mb-4">
                Tickets details will be available as the event approaches.
              </span>
            </div>
          </div>
        </div>
      )
      // return (
      //   <CardGuaranteed icon={partyIcon} text={`Your tickets for ${ticketDescription.toLowerCase()} have been secured.`} subtext="Details on how to access your tickets will be available as the event approaches." />
      // );
    }

    if (!publishedCards?.length) {
      return (
        <CardGuaranteed text={`Your package includes ${lowercaseFirstLetter(ticketDescription)}.`} subtext="Details on how to access your tickets will be available as the event approaches." />
      );
    }

    return cardsWithTickets.map((orderDetail: any) => {
      const ticketsRendered = renderTicketsForCard(orderDetail);

      return (
        <div key={orderDetail.cartDetails.packageDetailsId}>
          {ticketsRendered}
        </div>
      );
    });
  };

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
        setFile("");
      }}
      contentClassName="border-radius bg-white"
      className="modal-dialog-centered modal-secondary"
      size="lg"
      fade={false}
      id="ticketsModal"
    >
      <div className="modal-body p-0">
        <div className="d-flex justify-content-between p-4 border-bottom mb-2">
          <div className="d-flex align-items-center">
            <img className="mr-2" src={ticketsIcon} alt="tickets-icon" />
            <span className="tickets-details-span">Ticket Details</span>
          </div>
          
          <div className="d-flex align-items-center">
            {isModalOpened && file &&
              <button
              type="button"
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              onClick={handleDownload}
              >
                <img width="16" height="16" src={downloadBlackIcon} alt="download-icon" style={{ cursor: 'pointer' }}/>
              </button>
            }
            <div className="d-flex align-items-center">
              <button
                style={{ background: "none", border: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpened(false);
                  setFile("");
                }}
              >
                <i className="fas fa-times" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {file ? (
          <div className="px-4 py-2">
            <button className="btn btn-md p-1" onClick={() => { setFile(""); }}>
              &larr; Back To Tickets List
            </button>
            <div className="mt-2">
            {renderFile()}
            </div>
          </div>
        ) : ticketsLoading ? (
          <div className="d-flex justify-content-center p-4">
            <Spinner animation="border" variant="info" size="lg" />
          </div>
        ) : (
          <div className="px-4 py-2">
            <div className="details-content">
              <div className="mb-1">
                <span className="venue-name-span">{packageOrderWithDetails?.eventName}</span>
              </div>
              <div className="mb-1">
                <span className="venue-address-span">{address}</span>
              </div>
              <div className="mb-1">
                <span className="venue-date-span">
                  {packageOrderWithDetails?.eventDate?.includes('3:30 am')
                    ? packageOrderWithDetails.eventDate.replace('3:30 am', 'Time TBD')
                    : formatEventDate(packageOrderWithDetails?.eventDate)}
                </span>
              </div>
            </div>
                        
            <div className="divider"></div>

            {isShowEventTicketsAdditionalMessage ? (
              packageOrderWithDetails?.isTicketsSent ? (
                <>
                  <CardDetails
                      cardIcon={ticketConfigIcon}
                      cardTitle="Tickets"
                      cardSubtitle={`Your tickets were sent on ${moment(ticketJsonDataDate)?.format('MM/DD/YYYY') || ''}`}
                      isToggleExpanded={false}
                    ></CardDetails>
                  
                  <div className="divider" />
                  
                  <CardDetails
                    cardIcon={flagIcon}
                    cardTitle="Additional Notes"
                    cardDescription={
                      <span
                        style={{ whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{
                          __html: ticketJsonNotes || '',
                        }}>
                      </span>
                    }
                  />
                  
                  <div className="divider" />
                </>
              ) : (
                <>
                  <div className="mt-3">
                    <div>
                      <h2 className="text-capitalize">Event Tickets</h2>
                    </div>
                    <div>
                      <div className="d-flex align-items-center mt-2 justify-content-between">
                        <div style={{ width: "100%" }}>
                          <img src={calendarClockIcon} alt="clock-icon" className="mr-2" />
                          <span className="mb-4">
                            Tickets details will be available as the event approaches.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                </>
              )
            ) : null}
            {renderCards()}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TicketsModal;
